'use client';

import showReelVideo from '/videos/showreel.mp4';
import suziesPivoVideo from '/videos/suzies-pivo.mp4';
import suziesBurgerVideo from '/videos/suzies-burger.mp4';
import Video from 'next-video';
import "flickity/css/flickity.css";
import Flickity from "react-flickity-component";
import {useMediaQuery} from "react-responsive";

export default function VideoStripe() {
    const isMd = useMediaQuery({query: '(min-width: 768px)'})

    return <div className={'min-h-72'}>
        <Flickity className={'carousel'} elementType={'div'} static={false}
                  options={{freeScroll: false, lazyLoad: true, pageDots: false, groupCells: isMd ? 5 : false, resize: true, prevNextButtons: !isMd}}>
            <Video sizes={'(max-width: 768px) 90vw, 25vw'} src={suziesBurgerVideo} loop autoPlay playsInline controls={false} muted style={{height: 'auto', aspectRatio: '9/16', width: isMd ? '27%' : '100%', borderRadius: '24px'}}
            poster={'https://image.mux.com/6fqCrdWsiIa2DTTIwTJiXHkahBl9jHz6XbsNfqQWyw4/thumbnail.png?width=214&height=121&time=13'}
            blurDataURL={'https://image.mux.com/6fqCrdWsiIa2DTTIwTJiXHkahBl9jHz6XbsNfqQWyw4/thumbnail.png?width=214&height=121&time=13'}/>
            {isMd && <div style={{width: '30px'}}/>}
            <Video sizes={'(max-width: 768px) 90vw, 25vw'} src={showReelVideo} loop autoPlay playsInline controls={false} muted style={{height: 'auto', aspectRatio: '9/16', width: isMd ? '27%' : '100%', borderRadius: '24px'}}
            poster={'https://image.mux.com/JqNu9Ac01X00mN002TLrWmya1IB4DuDxc4EdIy2t5fUpRM/thumbnail.png?width=214&height=121&time=9'}
            blurDataURL={'https://image.mux.com/JqNu9Ac01X00mN002TLrWmya1IB4DuDxc4EdIy2t5fUpRM/thumbnail.png?width=214&height=121&time=9'}/>
            {isMd && <div style={{width: '30px'}}/>}
            <Video sizes={'(max-width: 768px) 90vw, 25vw'} src={suziesPivoVideo} loop autoPlay playsInline controls={false} muted style={{height: 'auto', aspectRatio: '9/16', width: isMd ? '27%' : '100%', borderRadius: '24px'}}
            poster={'https://image.mux.com/qToRSftvfYRFnKvimVT7g9mF7RNuSPRugo01gFeEKHoM/thumbnail.png?width=214&height=121&time=3'}
            blurDataURL={'https://image.mux.com/qToRSftvfYRFnKvimVT7g9mF7RNuSPRugo01gFeEKHoM/thumbnail.png?width=214&height=121&time=3'}/>
        </Flickity>
    </div>
}
