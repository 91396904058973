'use client';

import Lightbox, {SlideImage} from "yet-another-react-lightbox";
import {useEffect, useState} from "react";
import "yet-another-react-lightbox/styles.css";
import Download from "yet-another-react-lightbox/plugins/download";
import LightboxImage from "@/app/components/lightbox-image";
import Share from "yet-another-react-lightbox/plugins/share";
import {StaticImageData} from "next/image";

export default function GalleryLightbox({slides}: {slides: StaticImageData[]}) {
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const imgElements = document.querySelectorAll('.gallery img');
        imgElements.forEach((img, i) => {
            img.addEventListener('click', () => {
                setIndex(i % slides.length); // this is because of the carousel-loop layout which has two rows of images after each other
                setOpen(true);
            });
        });

        return () => {
            imgElements.forEach((img, i) => {
                img.removeEventListener('click', () => {
                    setIndex(i);
                    setOpen(true);
                });
            });
        };
    }, [slides.length]);

    return <div>
        <Lightbox styles={{container: {backgroundColor: '#000000dd', backdropFilter: 'blur(8px)', padding: 'calc(4% + 20px)'}}} plugins={[Download, Share]} render={{slide: LightboxImage}} controller={{closeOnBackdropClick: true, closeOnPullDown: true}}  className={`${open ? 'z-50' : '-z-50'}`}
                  open={open} index={index} slides={slides} close={() => setOpen(false)} />
    </div>;
}
