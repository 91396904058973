'use client'

import {useFormState} from 'react-dom'
import { HTMLAttributes, useEffect, useState } from 'react';
import {submitReservation} from "@/contact-actions";
import {Button, Checkbox, Input, Textarea} from "@nextui-org/react";
import { gtag } from '@/utils/gtagPush';
import { usePathname } from 'next/navigation';

interface Props extends HTMLAttributes<HTMLDivElement> {
}

const initialState = {
    message: '',
    success: undefined as boolean | undefined,
}

export default function ContactForm({className}: Props) {
    const [state, formAction] = useFormState(submitReservation, initialState);
    const [gaEventSent, setGaEventSent] = useState(false);

    const currentPath = usePathname();

    const showPhone = process.env.NEXT_PUBLIC_RESERVATION_FORM_FIELD_PHONE === 'true';
    const showNote = process.env.NEXT_PUBLIC_RESERVATION_FORM_FIELD_NOTE === 'true';
    const showSubscribeNewsletter = process.env.NEXT_PUBLIC_RESERVATION_FORM_FIELD_SUBSCRIBE_NEWSLETTER === 'true';

    useEffect(() => {
        if (state?.success && !gaEventSent) {
            gtag('event', 'generate_lead', { event_category: 'contact_form', event_label: currentPath, value: 1 });
            setGaEventSent(true);
        }
    }, [currentPath, gaEventSent, state?.success]);

    return (
        <div className={`${className} text-center md:text-left`}>
            {
                state?.success ? <div className={'mt-4 text-xl'}><p className={'text-green-100'}>{state.message}</p></div>
                    : <form className={'w-full'} action={formAction} autoComplete={'on'}>
                        <Input type={'text'} variant={'bordered'} required isRequired className={'w-full'} color={'primary'} label={'Celé jméno'} name={'name'} classNames={{
                            inputWrapper: 'border-primary/40 border-2 hover:!border-primary/80 !transition'
                        }} />
                        <div className={'mt-2 flex flex-col md:flex-row gap-2'}>
                            <Input type={'email'} variant={'bordered'} color={'primary'} required isRequired label={'E-mail'} classNames={{
                                inputWrapper: 'border-primary/40 border-2 hover:!border-primary/80 !transition'
                            }} className={'w-full'} name={'email'}/>
                            {showPhone &&
                                <Input type={'tel'} variant={'bordered'} required={false} color={'primary'} label={'Telefon'} className={'w-full'} name={'phone'} classNames={{
                                    inputWrapper: 'border-primary/40 border-2 hover:!border-primary/80 !transition'
                                }} />
                            }
                        </div>
                        {showNote &&
                            <Textarea placeholder={''} variant={'bordered'} label={'Zpráva'} color={'primary'} isRequired required className={'w-full mt-2'} name={'note'} classNames={{
                                inputWrapper: 'border-primary/40 border-2 hover:!border-primary/80 !transition'
                            }} />
                        }
                        {showSubscribeNewsletter &&
                            <div className={'mt-4'}>
                                <Checkbox checked={true} defaultChecked={true} color={'success'} className={'!outline-primary !border-primary'} name={'subscribe-newsletter'}><span className={''}>Přihlásit k odběru novinek</span></Checkbox>
                            </div>
                        }

                        <Button color={'default'} type={'submit'} variant={'solid'} className={'mt-4 w-full bg-primary/80 text-white md:w-40'}>{'Odeslat zprávu'}</Button>
                        {state?.message && <p className={`mt-4 text-red-200`}>{state.message}</p>}
                    </form>
            }
        </div>
    );
}
