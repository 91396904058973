'use client';

import React, { useLayoutEffect } from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

interface Props extends React.HTMLAttributes<HTMLDivElement>
{
  animation: any;
  loop: boolean;
  autoPlay?: boolean;
}

export default function Animation({ id, className, animation, loop, autoPlay = true }: Props) {
  const [shouldDisplay, setShouldDisplay] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  //
  // useLayoutEffect(() => {
  //   setLayoutFinished(true);
  // }, []);
  //
  // const play = () => {
  //   console.log(lottie);
  //   lottie?.play();
  // };
  //
  // useGSAP((context, contextSafe) => {
  //   let st: ScrollTrigger | undefined;
  //   const mm = gsap.matchMedia();
  //   mm.add(
  //     {
  //       isDesktop: '(min-width: 768px)',
  //       isMobile: '(max-width: 767px)',
  //     }, (context) => {
  //       const { isDesktop, isMobile } = context.conditions!;
  //       console.log(!autoPlay, lottie != null, layoutFinished)
  //       if (!autoPlay && lottie && layoutFinished)
  //       {
  //           st = ScrollTrigger.create({
  //             trigger: lottie.canvas as Element,
  //             markers: false,
  //             start: 'bottom bottom',
  //             end: isMobile ? '+=10%' : '+=50%',
  //             onEnter: play,
  //           });
  //       }
  //     });
  //
  //   return () => {
  //     st?.kill();
  //   };
  //
  // }, [autoPlay, layoutFinished, lottie]);

  // this solves the "failed loading animation data" error for some reason O_o
  useLayoutEffect(() => {
    if (containerRef.current && window.getComputedStyle(containerRef.current).display !== 'none')
    {
      setShouldDisplay(true);
    }
  }, [containerRef]);

  return (
    <div ref={ containerRef } className={ className }>{
      shouldDisplay && <DotLottieReact className={ 'w-full h-full' } src={ `/animations/${ animation }` } autoplay={ autoPlay } loop={ loop } />
    }</div>
  );
}
