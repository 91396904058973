'use client';

import React, { HTMLAttributes } from 'react';
import Image from 'next/image';

import styles from '@/app/components/our-customers.module.css';
import { useMediaQuery } from 'react-responsive';
import Flickity from 'react-flickity-component';

interface Props extends HTMLAttributes<HTMLDivElement>
{

}

export default function OurCustomers({ className }: Props) {
  const isMd = useMediaQuery({ query: '(min-width: 768px)' });
  const isLg = useMediaQuery({ query: '(min-width: 1024px)' });
  const isXl = useMediaQuery({ query: '(min-width: 1280px)' });
  const is2xl = useMediaQuery({ query: '(min-width: 1536px)' });
  const is3xl = useMediaQuery({ query: '(min-width: 1919px)' });
  const is4xl = useMediaQuery({ query: '(min-width: 1980px)' });

  const grouping = is3xl ? 5 : is2xl ? 4 : isMd ? 3 : false;

  const data = [
    { logo: '/customers/suzies.svg', name: 'Suzie`s', services: 'Videoklip a focení' },
    { logo: '/customers/cerveny-jelen.png', name: 'Červený Jelen a spol.', services: 'Školení marketingového týmu v oblasti Google Business profilu' },
    { logo: '/customers/friendship.png', name: 'Friendship', services: 'Optimalizace a správa Google Business profilu' },
    { logo: '/customers/udrevaka.svg', name: 'U Dřeváka', services: 'Komplexní péče - tvorba obsahu, správa sociálních sítí, webové stránky, automatizace meníček' },
    { logo: '/customers/sm-dk-ga.png', name: 'Více podniků', services: 'Software Restomat' },
  ];

  return (
    <div className={ `${ className }` }>
      <h3 className={ 'text-2xl lg:text-4xl font-medium mt-2 md:mt-4' }>Výběr našich klientů</h3>

      <Flickity className={ 'carousel block mt-4 h-max' } elementType={ 'div' } static={ false }
                options={ { freeScroll: true, lazyLoad: true, pageDots: false, groupCells: grouping, resize: true, prevNextButtons: !is3xl } }>
        { data.map((item, index) => (
          <div key={ `customer-${ index }` } className={'w-full mx-auto md:mx-0 md:w-[33%] 2xl:w-[25%] 3xl:w-[20%] py-4 h-96 2xl:h-[26rem]'}>
            <div
              className={ 'mx-auto h-full justify-items-center text-center bg-secondary/[3%] md:shadow-md transition hover:shadow-none p-8 rounded-2xl w-[96%]' }>
              <div className={ 'border-b-1 border-amber-900/20 pb-6 w-full' }>
                <Image src={ item.logo } alt={ item.name } width={ 512 } height={ 512 } className={ 'h-24 md:h-32 w-auto mx-auto' } />
              </div>
              <h4 className={ 'font-medium text-2xl py-2 border-b-1 border-amber-900/20 w-full' }>{ item.name }</h4>
              <div className={ 'font-light pt-4 text-zinc-700' }>{ item.services }</div>
            </div>
          </div>
        )) }
      </Flickity>
    </div>
  );
}
