import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/accordion/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/autocomplete/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/avatar/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/breadcrumbs/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/button/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/calendar/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/card/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/checkbox/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/chip/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/date-input/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/date-picker/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/dropdown/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/framer-utils/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/image/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/input/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/link/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/listbox/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/menu/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/modal/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/navbar/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/pagination/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/popover/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/progress/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/radio/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/ripple/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/scroll-shadow/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/select/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/slider/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/snippet/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/switch/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/system/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/table/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/tabs/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/tooltip/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/@nextui-org/user/dist/index.mjs");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/_MG_5523.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/_MG_5572.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/_MG_5742.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/_MG_6749.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/2.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/B4L-11.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/B4L-31.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/B4L-59.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/Cryptonight-71.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/DSC04467-Enhanced-NR.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/DSC06315.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/DSC06399.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/DSC06427.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/DSC06506.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/DSC06523.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/DSC09834.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/FSS-03.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/FSS-28.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/FSS-30.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/IMG_0069.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/Lucina.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/Olomouc-22.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/Olomouc-30.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/PopUp2024-2.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/SLOU DAYS -30.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/SLOU-20.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/Slovinsko-4.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/Summer_Vibes_Brno-13.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/Summer_Vibes_Brno-42.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/Summer_Vibes_Brno-48.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/Suzies-02.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/Suzies-06.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/Suzies-20.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/Suzies-21.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/Suzies-23.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/public/gallery/Suzies-24.jpg");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/src/app/components/Animation.tsx");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/src/app/components/contact-form.tsx");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/src/app/components/gallery-lightbox.tsx");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/src/app/components/global-animator.tsx");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/src/app/components/our-customers.tsx");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/src/app/components/smooth-scroller.tsx");
import(/* webpackMode: "eager" */ "/mnt/space/work/gastrostart-web/src/app/components/video-stripe.tsx")