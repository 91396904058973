'use client';

import SplitType from 'split-type';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { isIOS, isMac } from '@react-aria/utils';

export default function GlobalAnimator() {

  useGSAP(() => {
    const mm = gsap.matchMedia();
    mm.add(
      {
        isDesktop: '(min-width: 768px)',
        isMobile: '(max-width: 767px)',
      },
      (context) => {
        const { isDesktop, isMobile } = context.conditions!;
        const isStupid = isIOS();

        gsap.from('.hero-first-content', {
          duration: 0.4,
          scaleY: 0,
          delay: 0.5,
        });

        if (!isIOS())
        {
          const heroTl = gsap.timeline({
            scrollTrigger: {
              trigger: '.hero',
              start: isMobile ? '+=90% bottom' : '+=100% bottom',
              end: isMobile ? '+=270%' : '+=300%',
              pin: true,
              scrub: true,
              markers: false,
              toggleActions: 'play play reverse reverse',
            },
          });

          heroTl.to('.hero-first-content', {
            opacity: 0,
            delay: 1,
            duration: 2,
          }, 0);


          heroTl.to('.hero-second-content', {
            opacity: 1,
            duration: 1,
            delay: isStupid ? 1.5 : 0
          }, '<');

          if (!isStupid)
          {
            const splitSectionTwoBigText = new SplitType('.hero-section-two-big-text', { types: 'chars,words' });
            heroTl.from(splitSectionTwoBigText.chars, {
              // y: 100,
              scaleY: 0,
              // opacity: 0,
              duration: 0.3,
              stagger: 0.02,
              transformOrigin: '50% 70%',
            }, 3);
          }

          heroTl.from('.hero-section-two-quote', {
            x: -400,
            opacity: 0,
            delay: isStupid ? 1 : 3.5,
          }, isStupid ? 3 : 4);
        }
        else
        {
          gsap.to('.burger-mobile', {
            position: 'relative'
          });
          gsap.to('.hero-first-content', {
            position: 'relative',
            marginTop: '-5rem',
            height: '100vh'
          });
          gsap.to('.hero-second-content', {
            opacity: 1,
            position: 'relative',
            height: 'max-content',
            marginTop: '-5rem',
            marginBottom: '3rem'
          });

          gsap.to('#co-umime', {
            marginTop: '-5rem'
          });

          gsap.to('.hero-section-two-quote', {
            display: 'none'
          });

        }

        // heroTl.to('.hero-section-two-quote', {
        //   fontSize: isMobile ? '1.875rem' : '2.25rem',
        //   lineHeight: isMobile? '2.25rem' : '2.5rem',
        //   marginTop: '2rem',
        //   duration: 4,
        //   delay: 5,
        // }, 5);
        //
        // heroTl.to('.hero-section-two-big-text', {
        //   fontSize: isMobile ? '1rem' : '1.5rem',
        //   lineHeight: isMobile ? '1.5rem' : '2rem',
        //   duration: 4,
        //   delay: 5,
        // }, 5);
      },
    );
  }, []);

  return <></>;
}
